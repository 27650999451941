<template>
	<r-form ref="registerForm" :submitMethod="register">
		<v-layout wrap>
			<v-flex xs12>
				<PasswordInputs ref="passwordInputsRef" v-model="accountState.newAccount.password" :email="accountState.newAccount.email"></PasswordInputs>
			</v-flex>
			<v-flex v-if="recaptchaEnabled">
				<div id="registerRecaptchaId"></div>
			</v-flex>
		</v-layout>
		<v-layout justify-end>
			<r-btn type="submit">Create Account</r-btn>
		</v-layout>
	</r-form>
</template>

<script>
import PasswordInputs from '@/components/giving/account/PasswordInputs.vue';

export default {
	components: {
		PasswordInputs
	},
	props: {
		type: {
			type: String,
			validator: function(value) {
				return ['giverSignup'].includes(value);
			}
		}
	},
	data: function() {
		return {
			accountStore: _REALM.STORE.accountStore,
			accountState: _REALM.STORE.accountStore.state,
			siteState: _REALM.STORE.siteStore.state,
			transactionState: _REALM.STORE.transactionStore.state,
			recaptchaEnabled: _REALM.CONFIG.RecaptchaEnabled,
			recaptchaSiteKey: _REALM.CONFIG.RecaptchaSiteKey,
			recaptchaLoaded: false,
			registerRecaptchaWidget: null
		};
	},
	created() {
		if (this.recaptchaEnabled) {
			this.loadRecaptcha();
			window.registerRecaptchaCallBack = this.registerRecaptchaLoaded;
		}
	},
	methods: {
		register: function() {
			if (this.$refs.registerForm.validate() && this.$refs.passwordInputsRef.passwordsMatch() && this.ValidateRecaptcha()) {
				_REALM.LOADER.show();

				this.accountState.newAccount.confirmPassword = this.$refs.passwordInputsRef.confirmPassword;
				this.registerMethod().finally(function() {
					_REALM.LOADER.hide();
				});
			}
		},
		registerMethod() {
			var self = this;
			if (self.type === 'giverSignup') {
				return this.accountStore.addPassword();
			}
		},
		loadRecaptcha() {
			let recaptchaScript = document.createElement('script');
			recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=registerRecaptchaCallBack&render=explicit');

			recaptchaScript.async = true;
			recaptchaScript.defer = true;
			document.head.appendChild(recaptchaScript);
		},
		ValidateRecaptcha() {
			if (this.recaptchaEnabled) {
				let reCaptchaValue = '';
				reCaptchaValue = window.grecaptcha.getResponse(this.registerRecaptchaWidget);
				window.grecaptcha.reset(this.registerRecaptchaWidget);
				this.accountState.newAccount.reCaptchaValue = reCaptchaValue;

				if (reCaptchaValue.length === 0) {
					_REALM.TOAST.error("reCAPTCHA (I'm not a robot) must be checked.");
					return false;
				}
				return true;
			}
			return false;
		},
		registerRecaptchaLoaded() {
			this.registerRecaptchaWidget = window.grecaptcha.render(document.getElementById('registerRecaptchaId'), {
				sitekey: this.recaptchaSiteKey,
				class: 'g-recaptcha'
			});
		}
	}
};
</script>
