<template>
	<div v-if="imageId" v-show="showLogo">
		<div class="d-flex justify-center position-relative" :style="wrapperStyles">
			<div class="overflow-hidden" :style="logoStyles">
				<r-media-image ref="logoImage" :image-id="imageId" :width="size" :image-type="imageTypes.GIVING_FORM" @imageLoaded="showLogo = true" @imageError="showLogo = false"></r-media-image>
			</div>
			<div v-if="elevated" class="position-absolute elevation-2" :style="backgroundStyles"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		formType: {
			type: String,
			required: true,
			validator: value => ['Giving', 'Pledge'].includes(value)
		},
		elevated: Boolean
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			imageTypes: _REALM.STORE.mediaStore.ImageTypes,
			showLogo: _REALM.STORE.pledgeFormStore.state.pledgeForm.logoBorderRadius !== null,
			size: 120,
			yOffset: 0
		};
	},
	computed: {
		logoBorderRadius() {
			if (this.formType === 'Pledge') {
				return _REALM.STORE.pledgeFormStore.getLogoBorderRadius();
			} else if (this.formType === 'Giving') {
				return _REALM.STORE.givingFormStore.getLogoBorderRadius();
			}

			return 50;
		},
		wrapperStyles() {
			return {
				width: this.size + 'px',
				height: this.size + 'px'
			};
		},
		logoStyles() {
			return {
				top: this.yOffset + 'px',
				borderRadius: this.logoBorderRadius + '%',
				zIndex: 2
			};
		},
		backgroundStyles() {
			const styles = {
				width: this.size - 1 + 'px',
				height: this.size - 1 + 'px',
				top: this.yOffset + 1 + 'px',
				borderRadius: this.logoBorderRadius + '%',
				zIndex: 1
			};

			if (this.formType === 'Pledge') {
				styles.backgroundColor = this.pledgeFormState.pledgeForm.headerBackgroundColor;
			} else if (this.formType === 'Giving') {
				styles.backgroundColor = this.givingFormState.givingForm.headerBackgroundColor;
			}

			return styles;
		},
		imageId() {
			if (this.formType === 'Giving') {
				return this.givingFormState.givingForm.formId;
			} else if (this.formType === 'Pledge') {
				return this.pledgeFormState.pledgeForm.pledgeFormId;
			}

			return '';
		}
	},
	created() {
		if (this.formType === 'Pledge') {
			this.showLogo = _REALM.STORE.pledgeFormStore.state.pledgeForm.logoBorderRadius !== null;
		} else if (this.formType === 'Giving') {
			this.showLogo = _REALM.STORE.givingFormStore.state.givingForm.logoBorderRadius !== null;
		}
	}
};
</script>
