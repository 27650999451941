<template>
	<r-form ref="knownGiverSignIn" :submitMethod="ssoSignIn">
		<h6 class="mb-4">It looks like you have a Realm account. Sign in to access your saved information.</h6>
		<v-layout>
			<v-flex>
				<r-btn color="default" @click="backPage">Back</r-btn>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex text-right>
				<r-btn color="default" class="mr-2" @click="continueAsGuest">{{ continueBtnText }}</r-btn>
				<ActionButton type="submit" :color="btnColor" :font="fontType">Sign In</ActionButton>
			</v-flex>
		</v-layout>
	</r-form>
</template>
<script>
import ActionButton from '@/components/giving/sharedForms/ActionButton.vue';

export default {
	components: {
		ActionButton
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			transactionState: _REALM.STORE.transactionStore.state
		};
	},
	computed: {
		continueBtnText() {
			return _REALM.TOOLS.browser().mobile ? 'Continue' : 'Continue as Guest';
		},
		formEmail() {
			return this.isGivingForm ? this.givingFormState.transactionAccount.primaryEmail : this.pledgeFormState.pledgeAccount.primaryEmail;
		},
		isGivingForm() {
			return _REALM.STORE.layoutStore.isGivingForm();
		},
		btnColor() {
			return this.isGivingForm ? this.givingFormState.givingForm.buttonColor : this.pledgeFormState.pledgeForm.accentColor;
		},
		fontType() {
			return this.isGivingForm ? this.givingFormState.givingForm.font : this.pledgeFormState.pledgeForm.font;
		}
	},
	methods: {
		backPage() {
			if (this.isGivingForm) {
				this.givingFormState.currentDonorPage = 'GiveIntro';
			} else {
				this.pledgeFormState.currentDonorPage = 'PledgeIntro';
			}
		},
		continueAsGuest() {
			if (this.isGivingForm) {
				this.givingFormState.currentDonorPage = 'PaymentMethod';
			} else {
				this.savePledge();
			}
		},
		nextPage() {
			if (this.isGivingForm) {
				this.givingFormState.currentDonorPage = 'PaymentMethod';
			} else {
				this.pledgeFormState.currentDonorPage = 'PledgeIntro';
			}
		},
		ssoSignIn() {
			var returnLocation = _REALM.CONFIG.StratusHost + '/' + _REALM.CONFIG.siteUrl + '/give/' + _REALM.STORE.givingFormStore.state.givingForm.urlPath;

			const givingArgs = {
				IntegrationType: _REALM.STORE.transactionStore.state.transaction.integrationType,
				GivingFormData: _REALM.STORE.transactionStore.getGivingFormDataUrlString()
			};

			window.location.href = _REALM.CONFIG.StratusHost + '/' + _REALM.CONFIG.siteUrl + '/Account/Start?mode=signIn&returnUrl=' + returnLocation + '?queryString=' + JSON.stringify(givingArgs);
		},
		savePledge() {
			var self = this;
			_REALM.LOADER.show();
			var args = _REALM.STORE.pledgeStore.buildAddPledgeArgs();
			return _REALM.STORE.pledgeStore.addPledge(args).then(function() {
				_REALM.LOADER.hide();
				self.pledgeFormState.currentDonorPage = 'PledgeThankYou';
			});
		}
	}
};
</script>
