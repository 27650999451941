<template>
	<v-layout wrap>
		<v-flex :md6="!stackInputs" :xs12="stackInputs">
			<r-text-input v-model="password" :loading="showPasswordHelp" :minlength="8" :error-messages="passwordErrorMessages" :label="passwordLabel" type="password" required @focus="showPasswordHelp = true" @blur="showPasswordHelp = false" @input="validatePassword">
				<template v-slot:progress>
					<v-progress-linear v-if="showPasswordHelp" :value="passwordProgress" :color="passwordProgressColor" height="3" absolute></v-progress-linear>
				</template>
			</r-text-input>

			<v-tooltip v-if="!isMobile" :nudge-top="5" :value="showPasswordHelp" left>
				<template v-slot:activator="{ on }">
					<div v-on="on"></div>
				</template>
				<div>
					<div>
						<div class="subheading">Your password must have:</div>
						<ul>
							<li :class="{ 'success--text': passwordValidation.hasAtLeast8 }">At least 8 characters</li>
							<li>
								<span :class="{ 'success--text': passwordValidation.has3 }">At least 3 of the following:</span>
								<ul>
									<li :class="{ 'success--text': passwordValidation.hasUpper }">An uppercase character</li>
									<li :class="{ 'success--text': passwordValidation.hasLower }">A lowercase character</li>
									<li :class="{ 'success--text': passwordValidation.hasNumber }">A number</li>
									<li :class="{ 'success--text': passwordValidation.hasSymbol }">A symbol</li>
									<li :class="{ 'success--text': passwordValidation.hasAtLeast15 }">At least 15 characters</li>
								</ul>
							</li>
						</ul>
					</div>
					<div>
						<div class="subheading">Your password cannot have:</div>
						<ul>
							<li :class="{ 'warning--text': passwordValidation.hasRepeatingCharacters }">Any repeating characters</li>
							<li :class="{ 'warning--text': passwordValidation.hasSequences }">Any sequence (abc, qwe, 123, 321, etc...)</li>
							<li :class="{ 'warning--text': passwordValidation.hasEmailParts }">Any part of your email address</li>
						</ul>
					</div>
				</div>
			</v-tooltip>
		</v-flex>
		<v-flex :md6="!stackInputs" :xs12="stackInputs">
			<r-text-input v-model="confirmPassword" :minlength="8" :label="confirmPasswordLabel" :error-messages="passwordConfirmErrorMessages" type="password" required @input="validatePassword"></r-text-input>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	props: {
		value: String,
		email: {
			required: true
		},
		stackInputs: Boolean,
		passwordLabel: {
			default: 'Password'
		},
		confirmPasswordLabel: {
			default: 'Confirm Password'
		}
	},
	data: function() {
		return {
			password: null,
			confirmPassword: null,
			resetInputs: false,
			passwordErrorMessages: [],
			passwordConfirmErrorMessages: [],
			showPasswordHelp: false,
			passwordValidation: {
				passwordStrength: 0,
				hasAtLeast8: false,
				has3: false,
				hasUpper: false,
				hasLower: false,
				hasNumber: false,
				hasSymbol: false,
				hasAtLeast15: false,
				hasEmailParts: false,
				hasSequences: false,
				hasRepeatingCharacters: false
			}
		};
	},
	computed: {
		isMobile() {
			return _REALM.TOOLS.browser().mobile;
		},
		passwordProgress() {
			if (this.passwordValidation.hasRepeatingCharacters || this.passwordValidation.hasSequences || this.passwordValidation.hasEmailParts) {
				return 0;
			}

			return Math.min(100, this.passwordValidation.passwordStrength * 34);
		},
		passwordProgressColor() {
			if (this.passwordProgress === 0) {
				return 'error';
			} else if (this.passwordProgress < 100) {
				return 'warning';
			}

			return 'success';
		}
	},
	methods: {
		clearPasswords() {
			this.resetInputs = true;
			this.password = null;
			this.confirmPassword = null;
			this.passwordErrorMessages = [];
			this.passwordConfirmErrorMessages = [];
			this.resetInputs = false;
		},
		passwordsMatch() {
			this.passwordConfirmErrorMessages = [];
			if (this.password !== this.confirmPassword) {
				this.passwordConfirmErrorMessages.push(`${this.confirmPasswordLabel} does not match ${this.passwordLabel.toLowerCase()}.`);
				return false;
			}

			return true;
		},
		validatePassword() {
			this.$emit('input', this.password);
			var self = this;
			var emailAddress = self.email;
			var password = self.password;
			if (password === null) {
				return false;
			}

			var hasAtLeast8 = false;
			var has3 = false;
			var hasUpper = false;
			var hasLower = false;
			var hasNumber = false;
			var hasSymbol = false;
			var hasAtLeast15 = false;
			var hasEmailParts = false;
			var hasSequences = false;
			var hasRepeatingCharacters = false;
			self.passwordErrorMessages = [];
			self.passwordConfirmErrorMessages = [];

			if (password.length >= 8) {
				hasAtLeast8 = true;
			}

			var sequences = ['abcdefghijklmnopqrstuvwxyz', 'zyxwvutsrqponmlkjihgfedcba', 'qwertyuiop', 'poiuytrewq', 'asdfghjkl', 'lkjhgfdsa', 'zxcvbnm', 'mnbvcxz', '09876543210', '01234567890'];

			var lowerCasePassword = password.toLowerCase();
			outerLoop: for (var passwordIndex = 0; passwordIndex < password.length - 2; passwordIndex++) {
				var passwordChunk = lowerCasePassword.substr(passwordIndex, 3);
				for (var sequenceIndex = 0; sequenceIndex < sequences.length; sequenceIndex++) {
					if (sequences[sequenceIndex].indexOf(passwordChunk) >= 0) {
						this.passwordErrorMessages.push('Password cannot have any sequence (abc, qwe, 123, 321, etc...).');
						hasSequences = true;
						break outerLoop;
					}
				}
			}

			var count = 1;
			var chars = password.split('');
			for (var i = 1; i < chars.length; i++) {
				if (chars[i] === chars[i - 1]) {
					count++;
				} else {
					count = 1;
				}

				if (count === 3) {
					this.passwordErrorMessages.push('Password cannot have any repeating characters.');
					hasRepeatingCharacters = true;
					break;
				}
			}

			if (emailAddress) {
				var emailWords = emailAddress.split(/[,| @._\-#]/);
				for (var x = 0; x < emailWords.length; x++) {
					var emailWord = emailWords[x].toLowerCase();
					if (emailWord.length >= 3) {
						if (lowerCasePassword.indexOf(emailWord) >= 0) {
							this.passwordErrorMessages.push('Password cannot have any part of your email address.');
							hasEmailParts = true;
							break;
						}
					}
				}
			}

			self.passwordValidation.passwordStrength = 0;

			//lowercase
			if (/[a-z]/.test(password)) {
				self.passwordValidation.passwordStrength++;
				hasLower = true;
			}

			//uppercase
			if (/[A-Z]/.test(password)) {
				self.passwordValidation.passwordStrength++;
				hasUpper = true;
			}

			if (/[0-9]/.test(password)) {
				self.passwordValidation.passwordStrength++;
				hasNumber = true;
			}

			//symbols
			if (/[!-/]|[:-@]|[[-_]|[{-~]/.test(password)) {
				self.passwordValidation.passwordStrength++;
				hasSymbol = true;
			}

			//greater than 15 chars
			if (password.length >= 15) {
				self.passwordValidation.passwordStrength++;
				hasAtLeast15 = true;
			}

			if (self.passwordValidation.passwordStrength >= 3) {
				has3 = true;
			}

			self.passwordValidation.hasAtLeast8 = hasAtLeast8;
			self.passwordValidation.has3 = has3;
			self.passwordValidation.hasUpper = hasUpper;
			self.passwordValidation.hasLower = hasLower;
			self.passwordValidation.hasNumber = hasNumber;
			self.passwordValidation.hasSymbol = hasSymbol;
			self.passwordValidation.hasAtLeast15 = hasAtLeast15;
			self.passwordValidation.hasEmailParts = hasEmailParts;
			self.passwordValidation.hasSequences = hasSequences;
			self.passwordValidation.hasRepeatingCharacters = hasRepeatingCharacters;
		}
	}
};
</script>
