<template>
	<r-btn :class="computedClass" :color="computedColor" @click="$emit('click', $event)">
		<slot></slot>
	</r-btn>
</template>

<script>
export default {
	props: {
		color: String,
		font: String
	},
	computed: {
		computedClass() {
			return this.font + '-font-family';
		},
		computedColor() {
			return this.color;
		}
	}
};
</script>
