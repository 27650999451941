<style lang="scss" scoped>
.give-intro-title {
	font-size: 1.25rem !important;
	font-weight: 500;
	line-height: 2rem;
	letter-spacing: 0.0125em !important;
}
</style>
<template>
	<div class="give-intro-title" :class="computedFontClass"><slot></slot></div>
</template>

<script>
export default {
	props: {
		font: String
	},
	computed: {
		computedFontClass() {
			return this.font + '-font-family';
		}
	}
};
</script>
