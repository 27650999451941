<style lang="scss">
@import '@/scss/variables.scss';

.donor-nav-wrapper {
	&.donor-form-dark-theme {
		a,
		.v-icon {
			color: $white !important;
		}
	}

	&.donor-form-light-theme {
		a {
			color: $black !important;
		}
	}
}
</style>
<template>
	<v-layout row wrap class="donor-nav-wrapper justify-end not-selectable" :class="{ 'donor-form-dark-theme': formState.useDarkOverlayScheme, 'donor-form-light-theme': !formState.useDarkOverlayScheme }">
		<v-flex xs12 sm6 align-self-end text-right class="pt-0">
			<template v-if="isLoggedIn">
				<v-menu offset-y left class="ml-3">
					<template v-slot:activator="{ on }">
						<a v-on="on">
							Welcome, {{ accountState.authAccount.firstName }}
							<v-icon small>arrow_drop_down</v-icon>
						</a>
					</template>
					<v-list>
						<v-list-item v-for="(option, index) in computedOptions" :key="index" :href="option.route" :target="inIframe ? '_blank' : '_self'">
							<v-list-item-title>{{ option.text }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<a v-if="!isLoggedIn" :target="inIframe ? '_blank' : '_self'" :href="signInUrl">Sign In</a>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			siteState: _REALM.STORE.siteStore.state,
			accountState: _REALM.STORE.accountStore.state,
			realmVm: _REALM.VM,
			imageTypes: _REALM.STORE.mediaStore.ImageTypes,
			signInUrl: _REALM.CONFIG.SignInUrl
		};
	},
	computed: {
		formState() {
			return _REALM.STORE.layoutStore.isGivingForm() ? _REALM.STORE.givingFormStore.state.givingForm : _REALM.STORE.pledgeFormStore.state.pledgeForm;
		},
		isLoggedIn() {
			return _REALM.STORE.accountStore.isLoggedIn();
		},
		inIframe() {
			return _REALM.TOOLS.inIframe();
		},
		computedOptions() {      
			var options = [
				{
					text: 'Giving History',
					route: _REALM.TOOLS.buildSiteLink('Giving/GivingHistory')
				},
				{
					text: 'Scheduled Gifts',
					route: _REALM.TOOLS.buildSiteLink('Giving/GivingHistory?isUpcoming=true')
				},
				{
					text: 'Payment Methods',
					route: _REALM.TOOLS.buildSiteLink('Giving/Payments')
				},
				{
					text: 'Sign Out',
					route: _REALM.TOOLS.buildSiteLink('Account/SignOut')
				},
			];
			      
			if (this.accountState.authAccount.accountId && this.accountState.authAccount.accountId !== '00000000-0000-0000-0000-000000000000') {
				options.unshift({
					text: 'My Account',
					route: _REALM.TOOLS.buildSiteLink('Individual/MyProfile')
				});
			}

			return options;
		}
	}
};
</script>
