<style lang="scss">
@import '@/scss/variables.scss';

.donor-footer-wrapper {
	font-size: 12px !important;
	&.mobile-layout {
		font-size: 10px !important;
	}

	a {
		text-decoration: none !important;
	}

	&.donor-form-dark-theme {
		a,
		.v-icon {
			color: $white !important;
		}
	}

	&.donor-form-light-theme {
		a,
		.v-icon {
			color: $black !important;
		}
	}
}
</style>
<template>
	<v-layout class="donor-footer-wrapper" :class="{ 'mobile-layout': $vuetify.breakpoint.xs, 'donor-form-dark-theme': formState.useDarkOverlayScheme, 'donor-form-light-theme': !formState.useDarkOverlayScheme }">
		<v-flex text-uppercase>
			<a href="https://onrealm.org/" target="_blank">
				<v-icon size="14">lock</v-icon>
				&nbsp;Powered by {{ isChmsGivingIntegration ? 'ACS Technologies' : 'Realm' }}
			</a>
		</v-flex>
		<v-flex text-right>
			<a href="https://legal.acst.com/terms-service-use" target="_blank">Terms and Conditions</a>
			<a href="https://legal.acst.com/privacy-policy" target="_blank" class="ml-3">Privacy Policy</a>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
  data() {
    return {
      isChmsGivingIntegration: _REALM.STORE.siteStore.isChmsGivingIntegration()
    }
  },
	computed: {
		formState() {
			return _REALM.STORE.layoutStore.isGivingForm() ? _REALM.STORE.givingFormStore.state.givingForm : _REALM.STORE.pledgeFormStore.state.pledgeForm;
		}
	}
};
</script>
