<template>
	<div>
		<h5>Online giving is unavailable at this time. Please try again later.</h5>
		<v-layout>
			<v-flex text-right>
				<r-btn v-if="isGivingFormEmbed || !isGivingFormView" color="secondary" class="mr-2" @click="closeDonorForm">Close</r-btn>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
export default {
	computed: {
		isGivingFormEmbed() {
			return _REALM.STORE.givingFormStore.isGivingFormEmbed();
		},
		isGivingFormView() {
			return _REALM.VM.$route.params.givingFormUrl !== undefined;
		}
	},
	methods: {
		closeDonorForm() {
			_REALM.EVENT.emit('close-donor-form');
		}
	}
};
</script>
