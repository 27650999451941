<template>
	<v-row class="border-top border-bottom text-center align-stretch mx-0 mt-0 mb-2 py-2">
		<v-col class="py-0 d-flex justify-center align-center text-uppercase" :class="[{ 'mb-2': summaryHeaderAbove, 'mt-n1': summaryHeaderAbove }, summaryHeaderAbove ? 'col-12' : colSize]">
			<span class="subheading text-muted">Summary</span>
		</v-col>
		<v-col v-if="isRecurring" class="py-0" :class="[colSize, { 'border-left': !summaryHeaderAbove }]">
			<div class="font-weight-bold text-no-wrap initial-line-height" :class="{ subheading: $vuetify.breakpoint.xsOnly, headline: !$vuetify.breakpoint.xsOnly }">{{ singleAmount | centsToDollars }}</div>
			<div class="text-muted initial-line-height">{{ frequencyText | digitWordsToNumbers | lowerCase }}</div>
		</v-col>
		<v-col v-if="formType === 'Giving' && recurrenceCountIsDefinite" class="py-0 border-left" :class="[colSize]">
			<div class="font-weight-bold text-no-wrap initial-line-height" :class="{ subheading: $vuetify.breakpoint.xsOnly, headline: !$vuetify.breakpoint.xsOnly }">
				<v-progress-circular v-if="recurrenceCountLoading" indeterminate color="grey" :size="$vuetify.breakpoint.xsOnly ? 14 : 22"></v-progress-circular>
				<span v-else>{{ recurrenceCount }}</span>
			</div>
			<div class="text-muted initial-line-height">gifts scheduled</div>
		</v-col>
		<v-col v-if="displayTotal" class="py-0 border-left" :class="[colSize]">
			<div class="font-weight-bold text-no-wrap initial-line-height" :class="{ subheading: $vuetify.breakpoint.xsOnly, headline: !$vuetify.breakpoint.xsOnly }">
				<v-progress-circular v-if="totalLoading" indeterminate color="grey" :size="$vuetify.breakpoint.xsOnly ? 14 : 22"></v-progress-circular>
				<span v-else>{{ totalAmount | centsToDollars }}</span>
			</div>
			<div class="text-muted initial-line-height">{{ totalText }}</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		formType: {
			type: String,
			required: true,
			validator: value => ['Giving', 'Pledge'].includes(value)
		},
		recurrenceCountLoading: Boolean,
		totalLoading: Boolean,
		recurrenceCount: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			pledgeState: _REALM.STORE.pledgeStore.state,
			transactionState: _REALM.STORE.transactionStore.state,
			transactionStore: _REALM.STORE.transactionStore
		};
	},
	computed: {
		recurrenceCountIsDefinite() {
			if (this.formType === 'Pledge') {
				return this.isRecurring;
			} else if (this.formType === 'Giving') {
				const untilCancel = this.transactionState.transaction.completionType === this.transactionStore.enums.mint_Recurrence_CompletionType.UNTIL_I_CANCEL;
				const noStopDate = this.transactionState.transaction.completionType === this.transactionStore.enums.mint_Recurrence_CompletionType.STOP_DATE && !this.transactionState.transaction.stopDate;
				return this.isRecurring && !(untilCancel || noStopDate);
			}

			return false;
		},
		singleAmount() {
			if (this.formType === 'Pledge') {
				return this.pledgeState.pledge.amount;
			} else if (this.formType === 'Giving') {
				return this.transactionStore.totalGiftAmountWithProcessingCost();
			}

			return 0;
		},
		totalAmount() {
			return this.isRecurring ? this.singleAmount * this.recurrenceCount : this.singleAmount;
		},
		totalText() {
			if (this.formType === 'Pledge') {
				return 'total pledge';
			} else if (this.formType === 'Giving') {
				return this.recurrenceCountIsDefinite ? 'in total gifts' : 'total';
			}

			return 'total';
		},
		isRecurring() {
			if (this.formType === 'Pledge') {
				return !(this.pledgeState.pledge.frequency === STRATUS.EnumImports.PledgeFrequency.AsCan.Name);
			} else if (this.formType === 'Giving') {
				return !(this.transactionState.transaction.frequencyType === this.transactionStore.enums.mint_Recurrence_FrequencyType.ONCE);
			}

			return false;
		},
		frequencyText() {
			if (this.formType === 'Pledge') {
				return this.pledgeState.pledge.frequency ? STRATUS.EnumImports.PledgeFrequency[this.pledgeState.pledge.frequency].Text : '';
			} else if (this.formType === 'Giving') {
				const giftFrequency = this.transactionStore.getFrequencyData();
				return giftFrequency ? giftFrequency.Text : '';
			}

			return '';
		},
		displayTotal() {
			if (this.formType === 'Giving') {
				return this.isRecurring ? this.recurrenceCountIsDefinite : true;
			}

			return true;
		},
		summaryHeaderAbove() {
			return this.$vuetify.breakpoint.xsOnly && this.formType === 'Giving' && this.recurrenceCountIsDefinite;
		},
		colSize() {
			let numCols = this.summaryHeaderAbove ? 0 : 1;
			numCols += this.isRecurring ? 1 : 0;
			numCols += this.formType === 'Giving' && this.recurrenceCountIsDefinite ? 1 : 0;
			numCols += this.displayTotal ? 1 : 0;
			return `col-${12 / numCols}`;
		}
	}
};
</script>
